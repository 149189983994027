<template>
  <transition name="fade">

    <div class="d-flex flex-column flex-xl-row">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <div class="card mb-7" style="width: 100%;">
          <div class="card-body position-relative">

            <div class="row">
              <div class="col-xl-9">
                <div id="iframePDF" style="height:100% !important">
                  <embed type="application/pdf" :src="state.myPDF " style="width:100%; height:100%" />
                </div>
              </div>
              <div class="col-xl-3">
                <div>
                  <div class="bg-light-primary mt-1 mx-1 w-100 text-dark text-center" style="font-weight: 600;font-size: 14px; text-transform: uppercase;padding-top: 20px; padding-bottom: 20px;" >
                      BORDEREAU {{  state.myBord.bor_c_status  }}
                  </div>
                </div>
                <div v-if="state.BoutonValidDisplay">
                  <div v-if="state.myBord.tot_article < 1" class="alert alert-warning py-2 mx-1 mb-1 w-100">
                    <span class="svg-icon svg-icon-2x svg-icon-warning">
                      <inline-svg src="media/icons/duotune/general/gen044.svg"/>
                    </span>
                    <span class="fw-bold ms-3">Le bordereau doit contenir des articles pour être validé</span>
                  </div>
                  <a class="btn btn-sm btn-success mt-1 mx-1 w-100" :class="{'disabled': state.myBord.tot_article < 1}" style="font-size: 14px; text-transform: uppercase;padding-top: 20px; padding-bottom: 20px;" @click='ActWorkflow(1)' >
                    <span class="svg-icon svg-icon-1 svg-icon-primary">
                      <inline-svg src="media/icons/duotune/arrows/arr016.svg"/> </span>
                      {{ form.workflow.boutonValidation }}
                  </a>
                  <a class="btn btn-sm btn-light-danger mt-3 mx-1 w-100" style="font-size: 12px; text-transform: uppercase;" @click='ActWorkflow(-1)'>
                    <span class="svg-icon svg-icon-2 svg-icon-primary">
                      <inline-svg src="media/icons/duotune/arrows/arr015.svg"/> </span>
                      {{ form.workflow.boutonRefus }}
                  </a>
                </div>
                <Chat key="state.count" @newMessage="newMessage($event)" />
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import * as moment from "moment";
import Chat from "@/views/still/chat.vue";
import { ElMessageBox, ElNotification } from 'element-plus'

import mAxiosApi from "@/api";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "Overview",
  components: {
    Chat
  },
  setup() {
    moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myPDF : "" as any,
      myBord : {} as any,
      count  : 0,
      etapes: [] as any,
      parametrage: {} as any,
      BoutonValidDisplay: {} as any
    });
    const store = useStore();

    const form = reactive({
      type: '',
      producteur : '',
      dua : 30,
      tags : [] as any,
      refreglem : '',
      redacteur: 0,
      archiviste: 0,
      chefserv: 0,
      direction: 0,
      workflow: {} as any,
    })

    const newMessage = async () => {
      await chargementPage();
    };

    const tableHeader = ref([
      { name: "N° d'ordre", key: "art_n_num_ordre", sortable: false,},
      { name: "Titre", key: "art_c_categorie", sortable: false,},
      { name: "Libellé", key: "art_c_libelle", sortable: false,},
      { name: "Dates", key: "art_n_annee_mini", sortable: false,},
      { name: "DUA", key: "art_n_dua", sortable: false,},
      { name: "Observations", key: "art_c_observations", sortable: false,},
      { name: "", key: "art_n_seq", sortable: false,},

    ]);

    const tableFilters = ref([
      { name: "titre", key: "art_c_categorie", label: "art_c_categorie", },
    ]);

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };

    onMounted(async () => {
      if(window.localStorage.getItem('codeArk')){
        const parametrage = await getAxios("/getParametrage")
        store.dispatch(Actions.SET_PARAMETRAGE_ACTION, parametrage.results);
      }
      state.parametrage = store.getters.getParams;
      state.etapes = [
        { etat: 0, status: state.parametrage.wof_c_status_1, boutonRefus: state.parametrage.wof_c_refus_action_1, boutonValidation: state.parametrage.wof_c_validation_action_1, field: "bor_knum_utilisateur", prefix: "uti"},
        { etat: 1, status: state.parametrage.wof_c_status_2, boutonRefus: state.parametrage.wof_c_refus_action_2, boutonValidation: state.parametrage.wof_c_validation_action_2, field: "bor_knum_archiviste", prefix: "arch" },
        { etat: 2, status: state.parametrage.wof_c_status_3, boutonRefus: state.parametrage.wof_c_refus_action_3, boutonValidation: state.parametrage.wof_c_validation_action_3, field: "bor_knum_chef_service", prefix: "chef" },
        { etat: 3, status: state.parametrage.wof_c_status_4, boutonRefus: state.parametrage.wof_c_refus_action_4, boutonValidation: state.parametrage.wof_c_validation_action_4, field: "bor_knum_direction_archive", prefix: "dir" },
        { etat: 4, status: "Terminé", boutonRefus: "", boutonValidation: "", prefix: "" },
        { etat: 5, status: "Supprimé", boutonRefus: "", boutonValidation: "", prefix: "" },
      ]
      state.BoutonValidDisplay = computed(() => {
        if (!form.workflow) return false;
        if(!form.workflow.boutonValidation) return false;
        if(state.myBord.rol_c_code == "ADMINFONC" || state.myBord.rol_c_code == "SUPERADMIN") return true;
        if(state.myBord.bor_c_status.toUpperCase() == state.parametrage.wof_c_status_1.toUpperCase() && state.myBord.bor_knum_utilisateur != state.myBord.user_seq && state.myBord.bor_knum_archiviste != state.myBord.user_seq) return false;
        if(state.myBord.bor_c_status.toUpperCase() == state.parametrage.wof_c_status_2.toUpperCase() && state.myBord.bor_knum_archiviste != state.myBord.user_seq) return false;
        if(state.myBord.bor_c_status.toUpperCase() == state.parametrage.wof_c_status_3.toUpperCase() && state.myBord.bor_knum_chef_service != state.myBord.user_seq) return false;
        if(state.myBord.bor_c_status.toUpperCase() == state.parametrage.wof_c_status_4.toUpperCase() && state.myBord.bor_knum_direction_archive != state.myBord.user_seq) return false;
        return true;
      });
      await chargementPage();
    });

    const chargementPage = async () => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/pdfBordereau/" + parseInt(localStorage.getItem("currentBord") || "0"));
      state.myPDF = "data:application/pdf;base64," + myListLiv.pdf + "#toolbar=1&navpanes=1&scrollbar=0";

      const currentBord = parseInt(localStorage.getItem("currentBord") || "0");
      const myBordApi = await getAxios("/getSVoneBord/"+ currentBord);
      state.myBord = myBordApi.results

      if (!state.myBord.bor_knum_direction_archive) {
        console.log('Pas de direction archive');
        delete state.etapes[3];
        state.etapes[4].etat = 3;
        state.etapes[5].etat = 4;
        
        if (!state.myBord.bor_knum_chef_service) {
          console.log('Pas de chef de service');
          delete state.etapes[2];
          state.etapes[4].etat = 2;
          state.etapes[5].etat = 3;
        }
      }

      form.workflow = state.etapes.filter(
              (item: any) =>
                item.status == state.myBord.bor_c_status
            )[0];
      
      // console.log("mBord:", state.myBord.bor_knum_direction_archive);
            
      state.loaderEnabled = false;
    };

    const ActWorkflow = async (sens) => {

      let textAdd = "";
      const etatSuivant = form.workflow.etat + sens;
      let etapeSuivante = state.etapes.filter(
              (item: any) =>
                item.etat == etatSuivant
      );

      // console.log("Previsous", sens)
      // console.log("Previsous", etapeSuivante)

      if (sens == -1 && etapeSuivante.length == 0) {
        etapeSuivante = state.etapes.filter(
                (item: any) =>
                  item.status == 'Supprimé'
        );
        // console.log("etapeSuivante", etapeSuivante)
      }


      let StatusApply = "";

      if (etapeSuivante[0]) {
        // console.log("StatusApplyA");
        if (etapeSuivante[0].field) {

          const userSuivant = state.myBord[etapeSuivante[0].field]
          if (userSuivant) {
            const nextPrenom = state.myBord[etapeSuivante[0].prefix + "_c_prenom"];
            const nextNom = state.myBord[etapeSuivante[0].prefix + "_c_nom"];
            textAdd = "<br>Une notification sera envoyée à "+ nextPrenom +" "+ nextNom +" pour traitement du bordereau.";

          }

        }
      StatusApply = etapeSuivante[0].status;
      }

      const textDisplay = sens == 1 ? form.workflow.boutonValidation : form.workflow.boutonRefus;

      ElMessageBox.confirm(
        "<br><b>Souhaitez-vous réellement " + textDisplay +" ?</b>" + textAdd +"<br><br>", "Confirmation",
        {
          confirmButtonText: 'Oui, ' + textDisplay,
          cancelButtonText: 'Non',
          type: '',
          dangerouslyUseHTMLString: true,
        }
      )
        .then(async () => {
          // OK Bouton Validation
          const step = sens == -1 ? "previous" : "next"
          const response = await getAxios("/actWorkflow/" + step + "/" + form.workflow.field + "/" + state.myBord.bor_n_seq + "/" + StatusApply + "/" + textDisplay);
          store.dispatch(Actions.SET_INCREMENT_REFRESH_BORDEREAU);
          if (response.results == "error"){
            ElNotification({
              title: "Erreur",
              message: "Une erreur s'est produite, veuillez réessayer.",
              type: "error"
            })
          } 
          state.count += 1;
          await chargementPage();
        })
        .catch(() => {
          // Annulé
        })
    };

    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      form,
      ActWorkflow,
      newMessage
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>
